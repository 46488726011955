import PropTypes from 'prop-types';
import classnames from 'classnames';
import { handleCta } from 'bv-services';

import { altTextBuilder } from './helpers';
import LayoutABannerImg from './layout_a_banner_img';
import LayoutBBannerImg from './layout_b_banner_img';

const BannerItem = ({
  banner,
  isActive,
  prevItem,
  currentItem,
  nextItem,
  isPrevious,
  isFirst,
  activeIndex,
  bannerIndex,
  manualSliding,
  slidingBackwards,
}) => {
  const altText = altTextBuilder(banner.alt_text, banner.title);

  const queryString = `path=bv_banners&banner_id=${banner.id}`;

  return (
    <div
      id={`banner-${banner.id}`}
      style={
        banner.outer_background_color && {
          backgroundColor: banner.outer_background_color,
        }
      }
      className={classnames(
        'bv-slider-item',
        `bv-slider-item--${bannerIndex}`,
        {
          'is-clickable': banner.cta.action.length,
          [`animate-rtl ${manualSliding ? 'no-delay' : ''}`]:
            isActive && currentItem && !slidingBackwards,
          [`animate-ltr ${manualSliding ? 'no-delay' : ''}`]:
            (isActive && prevItem)
            || (isActive && currentItem && slidingBackwards),
          'animate-out': isPrevious,
          'animate-in': nextItem,
          'is-first': isFirst,
          'is-visible': isActive,
        },
      )}
      onClick={() => handleCta({ ...banner.cta, queryString })}
    >
      {banner?.type === 'LayoutB' ? (
        <LayoutBBannerImg
          bgUrl={banner.hero_background_image}
          textUrl={banner.hero_text_image}
          bannerIndex={bannerIndex}
          activeIndex={activeIndex}
          altText={altText}
        />
      ) : (
        <>
          <LayoutABannerImg
            bgUrl={banner.small_responsive_image.background}
            textUrl={banner.small_responsive_image.text}
            imageUrls={banner.small_responsive_image}
            bannerSize="small"
            bannerIndex={bannerIndex}
            activeIndex={activeIndex}
            altText={altText}
            media="(max-width: 767px)"
          />
          <LayoutABannerImg
            bgUrl={banner.large_responsive_image.background}
            textUrl={banner.large_responsive_image.text}
            imageUrls={banner.large_responsive_image}
            bannerSize="large"
            bannerIndex={bannerIndex}
            activeIndex={activeIndex}
            altText={altText}
            media="(min-width: 768px)"
          />
        </>
      )}
    </div>
  );
};

BannerItem.propTypes = {
  isPrevious: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool,
  currentItem: PropTypes.bool.isRequired,
  prevItem: PropTypes.bool.isRequired,
  nextItem: PropTypes.bool.isRequired,
  banner: PropTypes.instanceOf(Object).isRequired,
  activeIndex: PropTypes.number.isRequired,
  bannerIndex: PropTypes.number.isRequired,
  manualSliding: PropTypes.bool.isRequired,
  slidingBackwards: PropTypes.bool.isRequired,
};

BannerItem.defaultProps = {
  isFirst: false,
};

export default BannerItem;
