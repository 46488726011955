import PropTypes from 'prop-types';

import BannerItem from './banner_item';

const BannerSlider = ({
  banners,
  prevIndex,
  activeIndex,
  nextIndex,
  manualSliding,
  slidingBackwards,
  cycleCount,
}) => (
  <div className="bv-slider-inner">
    {
      banners.map((banner, bannerIndex) => (
        <BannerItem
          banner={banner}
          prevItem={bannerIndex < prevIndex
            && !(activeIndex === bannerIndex && activeIndex === 0)}
          currentItem={bannerIndex > prevIndex
            || (activeIndex === bannerIndex && activeIndex === 0)}
          nextItem={nextIndex === bannerIndex && slidingBackwards}
          isActive={activeIndex === bannerIndex}
          isPrevious={prevIndex === bannerIndex}
          activeIndex={activeIndex}
          bannerIndex={bannerIndex}
          isFirst={(activeIndex === bannerIndex && activeIndex === 0) && cycleCount === 1}
          manualSliding={manualSliding && [activeIndex, prevIndex].includes(bannerIndex)}
          slidingBackwards={slidingBackwards}
        />
      ))
    }
  </div>
);

BannerSlider.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  prevIndex: PropTypes.number.isRequired,
  nextIndex: PropTypes.number.isRequired,
  banners: PropTypes.instanceOf(Array).isRequired,
  manualSliding: PropTypes.bool.isRequired,
  slidingBackwards: PropTypes.bool.isRequired,
  cycleCount: PropTypes.number.isRequired,
};

export default BannerSlider;
