import { useState, useEffect } from 'react';
import { useFeature } from 'bv-hooks';
import PropTypes from 'prop-types';

const LayoutABannerImg = ({
  bgUrl,
  textUrl,
  bannerSize,
  altText,
  imageUrls,
  bannerIndex,
  activeIndex,
  media,
}) => {
  const [loaded, setLoaded] = useState([0, 1].includes(bannerIndex));
  const [newBannerLayoutsEnabled, featureResolved] = useFeature('new_banner_layouts');

  useEffect(() => {
    if (!loaded && bannerIndex === activeIndex + 1) {
      setLoaded(true);
    }
  }, [activeIndex]);

  if (!featureResolved) {
    return null;
  }

  return (
    <div className={`bv-slider-item__wrapper bv-slider-item__wrapper--${bannerSize}`}>
      {newBannerLayoutsEnabled ? (
        imageUrls?.webp && loaded && (
          <picture>
            <source srcSet={imageUrls.webp} type="image/webp" media={media} />
            { window.matchMedia(media).matches && <img height="170" width="100%" src={imageUrls.jpg} alt={altText} className="bv-slider-item__wrapper-bg" /> }
          </picture>
        )
      ) : (
        <>
          {bgUrl && loaded && (
            <picture>
              <source srcSet={bgUrl.replace('default', 'webp').replace(/[^.]+$/, 'webp')} type="image/webp" media={media} />
              { window.matchMedia(media).matches && <img src={bgUrl} alt={altText} className="bv-slider-item__wrapper-bg" /> }
            </picture>
          )}
          {textUrl && loaded && window.matchMedia(media).matches && (
            <div className="bv-slider-item__wrapper-text" style={{ backgroundImage: `url("${textUrl}")` }} />
          )}
        </>
      )}
    </div>
  );
};

LayoutABannerImg.propTypes = {
  altText: PropTypes.string,
  bgUrl: PropTypes.string,
  textUrl: PropTypes.string,
  media: PropTypes.string.isRequired,
  bannerSize: PropTypes.string.isRequired,
  imageUrls: PropTypes.instanceOf(Object),
  bannerIndex: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

LayoutABannerImg.defaultProps = {
  textUrl: null,
  bgUrl: null,
  altText: 'banner',
  imageUrls: {},
};

export default LayoutABannerImg;
