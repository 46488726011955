import PropTypes from 'prop-types';
import classnames from 'classnames';

const BannerNav = ({
  banners,
  activeIndex,
  jumpToSlide,
}) => (
  <ul className="bv-slider-nav">
    {[...Array(banners.length).keys()].map((bannerIndex) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <li
        className={classnames(
          'dot',
          { current: bannerIndex === activeIndex },
        )}
        onClick={() => jumpToSlide(bannerIndex)}
      />
    ))}
  </ul>
);

BannerNav.propTypes = {
  banners: PropTypes.instanceOf(Array).isRequired,
  activeIndex: PropTypes.number.isRequired,
  jumpToSlide: PropTypes.func.isRequired,
};

export default BannerNav;
