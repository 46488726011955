import PropTypes from 'prop-types';
import { useFetch, useLoggedIn, useFeature } from 'bv-hooks';
import { Skeleton } from 'bv-components';

import fetchBanners from './api/fetch_banners';
import BannersView from './banners_view';

const BannersContainer = ({ location, ...rest }) => {
  const loggedIn = useLoggedIn();
  const [banners, loading] = useFetch(() => fetchBanners(location), [location, loggedIn]);
  const [newBannerLayoutsEnabled, featureResolved] = useFeature('new_banner_layouts');

  if (loading || !featureResolved) return <Skeleton skeletonType="site-main-banner-wrapper" hasHeader={false} />;
  if (!banners.length) return null;

  return (
    <BannersView
      banners={banners}
      newBannerLayoutsEnabled={newBannerLayoutsEnabled}
      {...rest}
    />
  );
};

BannersContainer.propTypes = {
  location: PropTypes.string.isRequired,
};

export default BannersContainer;
