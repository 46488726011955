import { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  BANNER_INTERVAL, TIMEOUT_AFTER_MANUAL_SWIPE, SLIDER_ON,
} from '../config';

export default (banners) => {
  const [activeSlide, setActiveSlide] = useState(-1);
  const [prevIndex, setPrevIndex] = useState(-1);
  const [nextIndex, setNextIndex] = useState(0);
  const [swiped, setSwiped] = useState(null);
  const [loopOn, setLoopOn] = useState(true);
  const [manualSliding, setManualSliding] = useState(false);
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [slidingBackwards, setSlidingBackwards] = useState(false);
  const [cycleCount, setCycleCount] = useState(1);

  const bannerTimeOut = useRef();

  const handlers = useSwipeable({
    onSwipeStart: () => setManualSliding(true),
    onSwipedLeft: () => setSwiped('left'),
    onSwipedRight: () => setSwiped('right'),
  });

  const turnLoop = (mode = 'on') => {
    switch (mode) {
      case 'on':
        clearTimeout(bannerTimeOut.current);
        setTimeout(() => {
          setLoopOn(true);
        }, TIMEOUT_AFTER_MANUAL_SWIPE);
        break;
      case 'off':
        clearTimeout(bannerTimeOut.current);
        setLoopOn(false);
        setManualSliding(true);
        break;
      case 'auto':
        clearTimeout(bannerTimeOut.current);
        setLoopOn(false);
        bannerTimeOut.current = setTimeout(() => {
          setLoopOn(true);
        }, TIMEOUT_AFTER_MANUAL_SWIPE);
        break;
      default:
    }
  };

  const slideTo = (direction) => {
    if (direction === 'left') {
      setSlidingBackwards(false);
      setPrevIndex(activeSlide);
      setActiveSlide((activeSlide + 1) % banners.length);
      setNextIndex((activeSlide + 1) % (banners.length + 1));
      turnLoop('auto');
    } else if (isFirstSlide) {
      setSlidingBackwards(true);
      setPrevIndex(banners.length - 2);
      setActiveSlide(banners.length - 1);
      setNextIndex(1);
    } else {
      setSlidingBackwards(true);
      setPrevIndex(activeSlide - 2);
      setActiveSlide((activeSlide - 1) % banners.length);
      setNextIndex(activeSlide);
      turnLoop('auto');
    }
  };

  const jumpToSlide = (index) => {
    clearTimeout(bannerTimeOut.current);
    setActiveSlide(index);
    setPrevIndex(index - 1);
  };

  useEffect(() => {
    // first slide
    setPrevIndex(-1);
    setActiveSlide(0);
    setNextIndex(1);
  }, []);

  useEffect(() => {
    setIsFirstSlide(activeSlide === 0);

    if (prevIndex < 0) {
      setPrevIndex(banners.length - 1);
    }

    if (activeSlide === banners.length - 1) {
      setCycleCount((cycleCountState) => cycleCountState + 1);
      setNextIndex(0);
    }

    if (activeSlide !== -1 && loopOn && SLIDER_ON) {
      bannerTimeOut.current = setTimeout(
        () => {
          setSlidingBackwards(false);
          setPrevIndex(activeSlide);
          setActiveSlide((activeSlide + 1) % banners.length);
          setManualSliding(false);
        },
        BANNER_INTERVAL,
      );
    }
  }, [activeSlide, loopOn]);

  useEffect(() => {
    if (['left', 'right'].includes(swiped)) {
      clearTimeout(bannerTimeOut.current);
      setSwiped(false);
      slideTo(swiped);
    }
  }, [swiped]);

  return {
    handlers,
    actions: {
      jumpToSlide,
    },
    state: {
      prevIndex,
      activeSlide,
      nextIndex,
      manualSliding,
      slidingBackwards,
      cycleCount,
    },
  };
};
