import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { useFeature } from 'bv-hooks';
import { isEmpty } from 'underscore';

const wideBannerSizes = bvVar('wideBannerSizes');

const LayoutBBannerImg = ({
  bgUrl,
  textUrl,
  bannerIndex,
  activeIndex,
  altText,
}) => {
  const [loaded, setLoaded] = useState([0, 1].includes(bannerIndex));
  const [newBannerLayoutsEnabled, featureResolved] = useFeature('new_banner_layouts');

  useEffect(() => {
    if (!loaded && bannerIndex === activeIndex + 1) {
      setLoaded(true);
    }
  }, [activeIndex]);

  if (!featureResolved) {
    return null;
  }

  const sources = (url) => wideBannerSizes.map((banner) => (
    <source
      srcSet={newBannerLayoutsEnabled
        ? url[banner.size]
        : url.replace(/^\/+/, '')}
      media={banner.media}
      type={newBannerLayoutsEnabled
        ? 'image/webp'
        : 'image/jpg'}
    />
  ));

  return (
    <>
      {[
        { url: bgUrl, type: 'bg' },
        { url: textUrl, type: 'text' },
      ].map(({ url, type }) => {
        if (isEmpty(url)) {
          return null;
        }

        const fallbackUrl = newBannerLayoutsEnabled ? url.jpg || url.png : url;

        return (
          <picture>
            {loaded && sources(url)}
            <img
              src={loaded ? fallbackUrl : ''}
              alt={altText}
              width="100%"
              className={`bv-slider-item__${type}`}
            />
          </picture>
        );
      })}
    </>
  );
};

LayoutBBannerImg.propTypes = {
  bgUrl: PropTypes.string.isRequired,
  textUrl: PropTypes.string.isRequired,
  bannerIndex: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  altText: PropTypes.string,
};

LayoutBBannerImg.defaultProps = {
  altText: 'banner',
};

export default LayoutBBannerImg;
