import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

const addPromotionSegment = (title) => {
  const promoOfferRegExp = new RegExp(`${t('javascript.promotion')}|${t('javascript.offer')}`, 'i');

  return (title && !promoOfferRegExp.test(title)
    ? `${title} ${t('javascript.promotion')}`
    : title);
};

export const altTextBuilder = (altText, bannerTitle) => (altText || `${bvVar('siteName')} ${addPromotionSegment(bannerTitle || '')}`).trim();
