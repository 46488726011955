import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ScrollableContext } from 'bv-contexts';

import BannerNav from './components/banner_nav';
import BannerSlider from './components/banner_slider';
import useSlider from './hooks/use_slider';

const Banners = ({
  banners,
  newBannerLayoutsEnabled,
  className,
  hasNav,
}) => {
  const {
    handlers,
    actions: { jumpToSlide },
    state: {
      prevIndex,
      activeSlide,
      nextIndex,
      manualSliding,
      slidingBackwards,
      cycleCount,
    },
  } = useSlider(banners);

  const scrollableRef = useRef();

  return (
    <ScrollableContext.Provider value={scrollableRef}>
      <div
        className={classnames(
          'site-main-banner-wrapper', className, {
            'site-main-banner-wrapper--collapsed': !banners.length,
            'site-main-banner-wrapper--single': banners.length === 1,
            'site-main-banner-wrapper--wide': banners.length > 0 && banners[0].type === 'LayoutB',
            'site-main-banner-wrapper--no-text': newBannerLayoutsEnabled && banners[0].type === 'LayoutA',
          },
        )}
        ref={scrollableRef}
      >
        <div
          className="bv-slider"
          {...handlers}
        >
          <BannerSlider
            banners={banners}
            prevIndex={prevIndex}
            activeIndex={activeSlide}
            nextIndex={nextIndex}
            manualSliding={manualSliding}
            slidingBackwards={slidingBackwards}
            cycleCount={cycleCount}
          />
          { hasNav && (
          <BannerNav
            banners={banners}
            activeIndex={activeSlide}
            prevIndex={prevIndex}
            jumpToSlide={jumpToSlide}
          />
          )}
        </div>
      </div>
    </ScrollableContext.Provider>
  );
};

Banners.propTypes = {
  banners: PropTypes.instanceOf(Array).isRequired,
  newBannerLayoutsEnabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  hasNav: PropTypes.bool,
};

Banners.defaultProps = {
  className: '',
  hasNav: false,
};

export default Banners;
